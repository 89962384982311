import React, { Component } from 'react';
import {
    Col, Row, Card, CardBody, Container, Pagination, PaginationItem, PaginationLink, Table, Input, Button
} from 'reactstrap';
import './RemisionsList.scss';
import { Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { connect } from 'react-redux';
import { getRemisions, deleteRemision, editRemision, addRemision, returnRemision, printRemisionReturn, addExpense } from '../../services/RemisionServices';
import PaginationCustom from '../../components/pagination/Pagination';
import { confirmAlert } from 'react-confirm-alert';
import { logoutAction } from '../../redux/actions/UserActions';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { customersListAutocomplete } from '../../services/UserServices';
import Loading from '../../components/Loading';
import ModalCreateRemision from '../../components/ModalCreateRemision/ModalCreateRemision';
import * as XLSX from 'xlsx';
import ModalStatusRemision from '../../components/ModalStatusRemision/ModalStatusRemision';
import ModalPrintRemisions from '../../components/ModalPrintRemisions/ModalPrintRemisions';
import moment from 'moment';
import ModalAddExpenseRemitter from '../../components/ModalAddExpenseRemitter/ModalAddExpenseRemitter';

class RemisionsList extends Component {
    state = {
        breadcrumbItems: [
            { title: "Remisiones", link: "#" }
        ],
        remisions: [],
        page: 1,
        pages: 0,
        customers: [],
        customerSelect: { value: null, label: 'Filtrar por remitente' },
        showModalCreateRemision: false,
        remisionFromExcel: [],
        remisionSelect: null,
        showModalPrintRemisions: false,
        search: '',
        filter: { value: 'all', label: 'Mostrar todos' },
        initApplyLots: true,
        remitterExpenses: 0,
        showModalAddExpense: false,
        isRest: false,
        searchRemision: '',
        applyComision: false,
    }

    componentDidMount = async () => {
        toast.configure();
        this.fetchRemisions();
        this.fetchCustomers();
    }

    fetchRemisions = async (showSpinner = true) => {
        showSpinner && await this.setState({ loading: true });
        try {
            let response = await getRemisions({
                token: this.props.sessionProps.account.token,
                page: this.state.page,
                remitter: this.state.customerSelect.value,
                search: encodeURIComponent(this.state.search),
                searchRemision: encodeURIComponent(this.state.searchRemision),
                filter: this.state.filter.value
            });
            let remisions = response.data.data.remisions;
            for (let remision of remisions) {
                let temporal = document.createElement("div");
                temporal.innerHTML = remision.description;
                remision.description = temporal.textContent || temporal.innerText || "";
                remision.showButtonSaveTitle = false;
                remision.showButtonSavePriceBase = false;
                remision.showButtonSaveDescription = false;
                remision.showButtonSaveQuantity = false;
                remision.applyLots = this.state.initApplyLots;
            }
            await this.setState({
                pages: response.data.data.pages,
                remitterExpenses: response.data.data.expenses,
                remisions: remisions,
                loading: false
            });
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    handleChangeSearch = async (event) => {
        const { name, value } = event.target;
        await this.setState({
            [name]: value,
            page: 1
        });
        this.fetchRemisions(false);
    }

    saveRemision = async (item, title, description, priceBase, quantity, comision) => {
        await this.setState({ loading: true });
        try {
            let data = {
                id: item.id,
                token: this.props.sessionProps.account.token,
                applyLots: item.applyLots
            };
            if (title !== null) {
                data.title = title;
            }
            if (description !== null) {
                data.description = description;
            }
            if (priceBase !== null) {
                data.price_base = priceBase;
            }
            if (quantity !== null) {
                data.quantity = quantity;
            }
            if (comision !== null) {
                data.comision = comision;
                data.applyComision = this.state.applyComision;
            }
            let response = await editRemision(data);
            await this.setState({ loading: false });
            this.fetchRemisions();
            toast(`La remisión ha sido modificado con éxito`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 5000
            });
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    handleChangeRemisionTitle = async (event) => {
        let { name, value } = event.target;
        let remisions = this.state.remisions;
        for (let remision of remisions) {
            if (remision.id == name) {
                remision.title = value;
                remision.showButtonSaveTitle = true;
                break;
            }
        }
        await this.setState({
            remisions: remisions
        })
    }

    handleChangeRemisionQuantity = async (event) => {
        let { name, value } = event.target;
        let remisions = this.state.remisions;
        for (let remision of remisions) {
            if (remision.id == name) {
                remision.quantity = value;
                remision.showButtonSaveQuantity = true;
                break;
            }
        }
        await this.setState({
            remisions: remisions
        })
    }

    handleChangeRemisionComision = async (event) => {
        let { name, value } = event.target;
        let remisions = this.state.remisions;
        for (let remision of remisions) {
            if (remision.id == name) {
                remision.comision = value;
                remision.showButtonSaveComision = true;
                break;
            }
        }
        await this.setState({
            remisions: remisions
        })
    }

    handleChangeRemisionPriceBase = async (event) => {
        let { name, value } = event.target;
        let remisions = this.state.remisions;
        for (let remision of remisions) {
            if (remision.id == name) {
                remision.price_base = value;
                remision.showButtonSavePriceBase = true;
                break;
            }
        }
        await this.setState({
            remisions: remisions
        })
    }

    handleChangeRemisionDescription = async (event) => {
        let { name, value } = event.target;
        let remisions = this.state.remisions;
        for (let remision of remisions) {
            if (remision.id == name) {
                remision.description = value;
                remision.showButtonSaveDescription = true;
                break;
            }
        }
        await this.setState({
            remisions: remisions
        })
    }

    toggleChange = async (item) => {
        let remisions = this.state.remisions;
        for (let remision of remisions) {
            if (remision.id === item.id) {
                remision.applyLots = !item.applyLots;
                break;
            }
        }
        await this.setState({
            remisions: remisions
        })
    }

    returnRemision = async (id) => {
        confirmAlert({
            title: 'Devolver remisión',
            message: `¿Esta seguro que desea devolver la remisión seleccionada?`,
            buttons: [
                {
                    label: 'Cancelar'
                },
                {
                    label: 'Confirmar',
                    onClick: () => {
                        this._returnRemision(id)
                    }
                }
            ]
        });
    }

    _returnRemision = async (id) => {
        try {
            let response = await returnRemision({
                token: this.props.sessionProps.account.token,
                id: id
            });
            toast("Remisión ha sido devuelta con éxito", {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
            this.fetchRemisions();
        } catch (error) {
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                let msg = 'Ocurrió un error, reintentar.';
                if (error !== undefined && error.response !== undefined && error.response.data !== undefined) {
                    msg = error.response.data.message;
                }
                toast.error(msg, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    delete = async (id) => {
        confirmAlert({
            title: 'Eliminar remisión',
            message: `¿Esta seguro que desea eliminar la remisión seleccionada?`,
            buttons: [
                {
                    label: 'Cancelar'
                },
                {
                    label: 'Confirmar',
                    onClick: () => {
                        this._delete(id)
                    }
                }
            ]
        });
    }

    _delete = async (id) => {
        try {
            let response = await deleteRemision({
                token: this.props.sessionProps.account.token,
                id: id
            });
            toast("Remisión ha sido eliminada con éxito", {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
            this.fetchRemisions();
        } catch (error) {
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                let msg = `Ocurrió un error al procesar la solicitud, reintentar`
                if (error !== undefined && error.response !== undefined && error.response.data !== undefined) {
                    msg = error.response.data.message;
                }
                toast.error(msg, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    onPrev = async () => {
        await this.setState({ page: this.state.page - 1 });
        this.fetchRemisions();
    }

    onNext = async () => {
        await this.setState({ page: this.state.page + 1 });
        this.fetchRemisions();
    }

    onPage = async (page) => {
        await this.setState({ page: page });
        this.fetchRemisions();
    }

    fetchCustomers = async () => {
        try {
            let response = await customersListAutocomplete({
                token: this.props.sessionProps.account.token,
                onlyRemitter: true
            });
            await this.setState({
                customers: response.data.data.customers
            });
        } catch (error) {
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    handleSelectChange = async (selectedOption) => {
        await this.setState({ customerSelect: selectedOption, page: 1 });
        this.fetchRemisions();
    };

    handleUpload = (e) => {
        e.preventDefault();
        var files = e.target.files, f = files[0];
        var reader = new FileReader();
        reader.onload = async function (e) {
            var data = e.target.result;
            let readedData = XLSX.read(data, { type: 'binary' });
            let wsname = readedData.SheetNames[0];
            let ws = readedData.Sheets[wsname];

            let remisions = XLSX.utils.sheet_to_json(ws, { header: "A", defval: null, range: 2 });
            let _tmpRemisions = [];
            let remisionDuplicated = null;
            for (let item of remisions) {
                if (_tmpRemisions.includes(item.B)) {
                    remisionDuplicated = item.B;
                    break;
                }
                _tmpRemisions.push(item.B);
            }
            if (remisionDuplicated !== null) {
                confirmAlert({
                    title: 'Remisión repetida',
                    message: `¿La remisión ${remisionDuplicated} se encuentra repetida, desea continuar?`,
                    buttons: [
                        {
                            label: 'Cancelar'
                        },
                        {
                            label: 'Confirmar',
                            onClick: async () => {
                                await this.setState({
                                    showModalCreateRemision: true,
                                    remisionFromExcel: remisions
                                });
                            }
                        }
                    ]
                });
            }
            else {
                await this.setState({
                    showModalCreateRemision: true,
                    remisionFromExcel: remisions
                });
            }
        }.bind(this);
        e.target.value = '';
        reader.readAsBinaryString(f)
    }

    closeModalCreate = async () => {
        await this.setState({
            showModalCreateRemision: false,
            remisionFromExcel: []
        });
    }

    createRemisionFromExcel = async () => {
        let remisions = [];
        this.state.remisionFromExcel.map(item => {
            remisions.push({
                title: item.C,
                description: `${item.D !== null ? item.D : ''} ${item.E !== null ? item.E : ''} ${item.F !== null ? item.F : ''}`,
                quantity: item.G !== null ? item.G : 1,
                price_base: item.H !== null ? item.H : 0,
                remision: item.B,
                comision: item.I
            })
        });
        await this.setState({ loading: true });
        try {
            let data = {
                token: this.props.sessionProps.account.token,
                remisions: remisions
            };
            let response = await addRemision(data);
            await this.setState({ loading: false });
            this.closeModalCreate();
            toast(`Las remisiones han sido creadas con éxito`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 5000
            });
        } catch (error) {
            await this.setState({ loading: false });
            let msg = 'Ocurrió un error, reintentar.';
            if (error !== undefined && error.response !== undefined && error.response.data !== undefined) {
                msg = error.response.data.message;
            }
            toast.error(msg, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
        }
    }

    close = async () => {
        await this.setState({
            remisionSelect: null
        });
    }

    openModalPrint = async () => {
        if (this.state.customerSelect.value === null) {
            toast.error(`Debe seleccionar un remitente para imprimir`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
            return;
        }
        await this.setState({ showModalPrintRemisions: true });
    }

    closeModalPrint = async () => {
        await this.setState({ showModalPrintRemisions: false });
    }

    changeFilter = async (e) => {
        await this.setState({ filter: e })
        this.fetchRemisions();
    }

    closeModalAddExpense = async () => {
        await this.setState({ showModalAddExpense: false })
    }

    _downloadPdfs = () => {
        confirmAlert({
            title: 'Descargar reporte de ventas',
            message: `¿Esta seguro que desea descargar el reporte de ventas?`,
            buttons: [
                {
                    label: 'Cancelar'
                },
                {
                    label: 'Confirmar',
                    onClick: () => {
                        this.downloadPdfs()
                    }
                }
            ]
        });
    }

    downloadPdfs = async (id) => {
        await this.setState({ loading: true });
        try {
            let response = await printRemisionReturn({
                token: this.props.sessionProps.account.token,
                id: id
            });
            let link = document.createElement('a');
            link.href = response.data.data.url;
            link.target = "_blank";
            document.body.appendChild(link);
            link.click();
            await this.setState({ loading: false });
        } catch (error) {
            await this.setState({ loading: false });
            let msg = 'Ocurrió un error, reintentar.';
            if (error !== undefined && error.response !== undefined && error.response.data !== undefined) {
                msg = error.response.data.message;
            }
            toast.error(msg, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
        }
    }

    addNewExpense = async (amount) => {
        try {
            let response = await addExpense({
                token: this.props.sessionProps.account.token,
                id_remitter: this.state.customerSelect.value,
                amount: amount
            });
            await this.setState({
                remitterExpenses: response.data.data.expenses,
                showModalAddExpense: false
            });
            toast(`Gasto creado con éxito`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 5000
            });
        } catch (error) {
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    toggleChangeComision = () => {
        this.setState({
            applyComision: !this.state.applyComision
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    {this.state.loading && <Loading />}
                    {
                        this.state.showModalCreateRemision &&
                        <ModalCreateRemision remisions={this.state.remisionFromExcel} close={() => this.closeModalCreate()}
                            createRemisions={() => this.createRemisionFromExcel()} />
                    }
                    {
                        this.state.showModalPrintRemisions &&
                        <ModalPrintRemisions close={this.closeModalPrint} remitter={this.state.customerSelect}
                            filter={this.state.filter.value} />
                    }
                    {
                        this.state.remisionSelect !== null &&
                        <ModalStatusRemision close={this.close} id={this.state.remisionSelect} />
                    }
                    <Container fluid>
                        <Breadcrumbs title="Remisiones" breadcrumbItems={this.state.breadcrumbItems} />
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody className="without-padding">
                                        <div class="row" style={{ marginBottom: '1rem' }}>
                                            <div className="col-12 col-md-4">
                                                <Select
                                                    className="select2 select2-multiple"
                                                    placeholder="Seleccionar filtro de remitente"
                                                    options={this.state.customers.map(item => ({ value: item.id, label: `${item.id} - ${item.name}` }))}
                                                    value={this.state.customerSelect}
                                                    onChange={this.handleSelectChange}
                                                    required
                                                />
                                                {
                                                    this.state.customerSelect.value && (
                                                        <label style={{ marginTop: '1rem', fontSize: '24px', marignLeft: '1rem' }}>
                                                            Gastos: $ {this.state.remitterExpenses}
                                                            <Link onClick={() => this.setState({ showModalAddExpense: true, isRest: false })} className="text-success save-edit">
                                                                <i className="mdi mdi-plus-circle font-size-18"></i>
                                                            </Link>
                                                            <Link style={{ marginLeft: '1rem' }} onClick={() => this.setState({ showModalAddExpense: true, isRest: true })} className="text-danger save-edit">
                                                                <i className="mdi mdi-minus-circle font-size-18"></i>
                                                            </Link>
                                                        </label>
                                                    )
                                                }
                                                {
                                                    this.state.showModalAddExpense && (
                                                        <ModalAddExpenseRemitter remitter={this.state.customerSelect} isRest={this.state.isRest}
                                                            close={() => this.closeModalAddExpense()} addNewExpense={(amount) => this.addNewExpense(amount)} />
                                                    )
                                                }
                                            </div>
                                            <div className="col-12 col-md-2">
                                                <div className="form-inline">
                                                    <div className="search-box" style={{ width: '100%' }}>
                                                        <div className="position-relative">
                                                            <Input type="text" name="search" className="form-control rounded" placeholder="Buscar por nombre o descripción"
                                                                value={this.state.search} onChange={this.handleChangeSearch} style={{ width: '100%' }} />
                                                            <i className="mdi mdi-magnify search-icon" ></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-2">
                                                <div className="form-inline">
                                                    <div className="search-box" style={{ width: '100%' }}>
                                                        <div className="position-relative">
                                                            <Input type="text" name="searchRemision" className="form-control rounded" placeholder="Buscar por remisión"
                                                                value={this.state.searchRemision} onChange={this.handleChangeSearch} style={{ width: '100%' }} />
                                                            <i className="mdi mdi-magnify search-icon" ></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <Select
                                                    name="orderBy"
                                                    className="select2 select2-multiple"
                                                    options={[
                                                        { value: 'onlyunsold', label: 'Mostrar solo sin vender' },
                                                        { value: 'onlysold', label: 'Mostrar solo vendidas' },
                                                        { value: 'onlyreturn', label: 'Mostrar solo devueltas' },
                                                        { value: 'onlywithoutlot', label: 'Mostrar solo sin lotear' },
                                                        { value: 'all', label: 'Mostrar todos' },
                                                    ]}
                                                    value={this.state.filter}
                                                    onChange={(e) => this.changeFilter(e)}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 text-right div-btn-add" style={{ justifyContent: 'flex-end' }}>
                                                <Button onClick={() => this.openModalPrint()} color="info" className="btn btn-success w-xs" style={{ padding: '3px 1rem' }}>
                                                    <i className="mdi mdi-printer"></i> Imprimir
                                                </Button>
                                                <Link to="remisiones/0" className="btn btn-success btn-add w-xs" style={{ marginLeft: '0.5rem' }}>
                                                    <i className="mdi mdi-plus"></i> Agregar
                                                </Link>
                                                <input className="ml-2" type="file" name="file" onChange={this.handleUpload} />
                                            </div>
                                        </div>
                                        <div className="row" style={{ paddingTop: '.5rem' }}>
                                            <div className="col-12 text-center">
                                                <PaginationCustom page={this.state.page} pages={this.state.pages}
                                                    onPrev={this.onPrev} onNext={this.onNext} onPage={this.onPage} />
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                            <Table className="mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>Remisión</th>
                                                        <th>Nombre</th>
                                                        <th>Descripción</th>
                                                        <th>Remitente</th>
                                                        <th width="10%">Precio base</th>
                                                        <th width="5%">Cantidad</th>
                                                        <th width="5%">Comisión</th>
                                                        {
                                                            this.state.filter.value !== 'onlysold' &&
                                                            <th>Cant. sin vender</th>
                                                        }
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.remisions.map(item =>
                                                            <tr>
                                                                <th scope="row">{item.id_remitter}/{item.remision}</th>
                                                                <td>
                                                                    <input name={item.id} value={item.title} onChange={this.handleChangeRemisionTitle} style={{ width: '100%' }}
                                                                        onKeyDown={(e) => { if (e.keyCode === 13) { this.saveRemision(item, item.title, null, null, null) } }} />
                                                                    {
                                                                        item.showButtonSaveTitle &&
                                                                        <Link onClick={() => this.saveRemision(item, item.title, null, null, null)} className="text-success save-edit">
                                                                            <i className="mdi mdi-check-circle font-size-18"></i>
                                                                        </Link>
                                                                    }
                                                                </td>
                                                                <td>
                                                                    <input name={item.id} value={item.description} onChange={this.handleChangeRemisionDescription} style={{ width: '100%' }}
                                                                        onKeyDown={(e) => { if (e.keyCode === 13) { this.saveRemision(item, null, item.description, null, null) } }} />
                                                                    {
                                                                        item.showButtonSaveDescription &&
                                                                        <Link onClick={() => this.saveRemision(item, null, item.description, null, null)} className="text-success save-edit">
                                                                            <i className="mdi mdi-check-circle font-size-18"></i>
                                                                        </Link>
                                                                    }
                                                                    {
                                                                        item.showButtonSaveDescription &&
                                                                        <label>
                                                                            <input type="checkbox" defaultChecked={item.applyLots} onChange={() => this.toggleChange(item)} />
                                                                            &nbsp; Aplicar en lotes
                                                                        </label>
                                                                    }
                                                                </td>
                                                                <td>{item.remitter?.name}</td>
                                                                <td>
                                                                    <input type="number" name={item.id} value={item.price_base} onChange={this.handleChangeRemisionPriceBase} style={{ width: '100%' }}
                                                                        onKeyDown={(e) => { if (e.keyCode === 13) { this.saveRemision(item, null, null, item.price_base, null) } }} />
                                                                    {
                                                                        item.showButtonSavePriceBase &&
                                                                        <Link onClick={() => this.saveRemision(item, null, null, item.price_base, null)} className="text-success save-edit">
                                                                            <i className="mdi mdi-check-circle font-size-18"></i>
                                                                        </Link>
                                                                    }
                                                                    {
                                                                        item.showButtonSavePriceBase &&
                                                                        <label>
                                                                            <input type="checkbox" defaultChecked={item.applyLots} onChange={() => this.toggleChange(item)} />
                                                                            &nbsp; Aplicar en lotes
                                                                        </label>
                                                                    }
                                                                </td>
                                                                <td>
                                                                    <input name={item.id} value={item.quantity} onChange={this.handleChangeRemisionQuantity} style={{ width: '100%' }}
                                                                        onKeyDown={(e) => { if (e.keyCode === 13) { this.saveRemision(item, null, null, null, item.quantity) } }} />
                                                                    {
                                                                        item.showButtonSaveQuantity &&
                                                                        <Link onClick={() => this.saveRemision(item, null, null, null, item.quantity)} className="text-success save-edit">
                                                                            <i className="mdi mdi-check-circle font-size-18"></i>
                                                                        </Link>
                                                                    }
                                                                </td>
                                                                <td>
                                                                    <input name={item.id} value={item.comision} onChange={this.handleChangeRemisionComision} style={{ width: '100%' }}
                                                                        onKeyDown={(e) => { if (e.keyCode === 13) { this.saveRemision(item, null, null, null, null, item.comision) } }} />
                                                                    {
                                                                        item.showButtonSaveComision &&
                                                                        <Link onClick={() => this.saveRemision(item, null, null, null, null, item.comision)} className="text-success save-edit">
                                                                            <i className="mdi mdi-check-circle font-size-18"></i>
                                                                        </Link>
                                                                    }
                                                                    {
                                                                        item.showButtonSaveComision &&
                                                                        <label style={{
                                                                            fontSize: '10px',
                                                                            textAlign: 'center',
                                                                            alignItems: 'self-start', marginTop: '5px'
                                                                        }}>
                                                                            <input type="checkbox" defaultChecked={false} onChange={() => this.toggleChangeComision()} />
                                                                            &nbsp; Actualizar remitente
                                                                        </label>
                                                                    }
                                                                </td>
                                                                {
                                                                    this.state.filter.value !== 'onlysold' &&
                                                                    <td>
                                                                        {item.quantityunsold}
                                                                        {
                                                                            item.returned ?
                                                                                <div className="badge badge-soft-danger font-size-10">
                                                                                    &nbsp; Dev. {moment(item.returned).format('DD/MM/YYYY')}
                                                                                </div>
                                                                                : null
                                                                        }
                                                                    </td>
                                                                }
                                                                <td>
                                                                    <Link to={`remisiones/${item.id}`} className="mr-1 text-primary"><i className="mdi mdi-pencil font-size-18"></i></Link>
                                                                    <Link onClick={() => this.setState({ remisionSelect: item.id })} className="text-success mr-1"><i className="mdi mdi-format-list-numbered font-size-18"></i></Link>
                                                                    <Link onClick={() => this.delete(item.id)} className="text-danger"><i className="mdi mdi-trash-can font-size-18"></i></Link>
                                                                    {
                                                                        !item.returned && parseInt(item.quantityunsold) > 0 ?
                                                                            <Link onClick={() => this.returnRemision(item.id)} className="text-danger"><i className="mdi mdi-export font-size-18"></i></Link>
                                                                            : null
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                        <PaginationCustom page={this.state.page} pages={this.state.pages}
                                            onPrev={this.onPrev} onNext={this.onNext} onPage={this.onPage} />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer
});

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => {
            dispatch(logoutAction());
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RemisionsList);